import BasePlugin from '../BasePlugin'

export default class ScenarioSaveCallback extends BasePlugin {
  async execute () {
    let months = []
    months[0] = 'январь'
    months[1] = 'февраль'
    months[2] = 'март'
    months[3] = 'апрель'
    months[4] = 'май'
    months[5] = 'июнь'
    months[6] = 'июль'
    months[7] = 'август'
    months[8] = 'сентябрь'
    months[9] = 'октябрь'
    months[10] = 'ноябрь'
    months[11] = 'декабрь'
    let card = this.context.getCard().data
    this.context.$http.get(`${this.context.$config.api}/registryservice/plugins/execute/FindEarlyAndLateValuesByPeriodByScenarioIdQuery?scenarioId=${card.id}`,
      {},
      {}
    ).then((response) => {
      let earlyFact = response.data.early
      if (earlyFact !== null) {
        let earlyFactParts = earlyFact.split('-')
        earlyFact = new Date(parseInt(earlyFactParts[2]), parseInt(earlyFactParts[1]) - 1, earlyFactParts[0])
      } else {
        return
      }
      let lastFact = response.data.last
      if (lastFact !== null) {
        let lastFactParts = lastFact.split('-')
        lastFact = new Date(parseInt(lastFactParts[2]), parseInt(lastFactParts[1]) - 1, lastFactParts[0])
      } else {
        return
      }
      let earlyPlan = card.attr_4040_
      let lastPlan = card.attr_4041_
      let message = 'Бюджеты сценария содержат сведения за пределами указанного периода расчетов:'
      if (earlyPlan === null || lastPlan === null) {
        return
      }
      earlyPlan = new Date(earlyPlan)
      lastPlan = new Date(lastPlan)
      let earlyError = false
      if (earlyPlan.getFullYear() > earlyFact.getFullYear()) {
        earlyError = true
      } else if (earlyPlan.getFullYear() === earlyFact.getFullYear()) {
        if (earlyPlan.getMonth() > earlyFact.getMonth()) {
          earlyError = true
        }
      }
      let lastError = false
      if (lastPlan.getFullYear() < lastFact.getFullYear()) {
        lastError = true
      } else if (lastPlan.getFullYear() === lastFact.getFullYear()) {
        if (lastPlan.getMonth() < lastFact.getMonth()) {
          lastError = true
        }
      }

      if (earlyError) {
        if ([2015, 2016, 2017, 2018].includes(earlyFact.getFullYear())) {
          message += `<br>Год первого значения: ${earlyFact.getFullYear()}`
        } else {
          message += `<br>Дата первого значения: ` + months[earlyFact.getMonth()] + ` ${earlyFact.getFullYear()}`
        }
      }
      if (lastError) {
        if ([2015, 2016, 2017, 2018].includes(lastFact.getFullYear())) {
          message += `<br>Год последнего значения: ${lastFact.getFullYear()}`
        } else {
          message += `<br>Дата последнего значения: ` + months[lastFact.getMonth()] + ` ${lastFact.getFullYear()}`
        }
      }
      if (earlyError || lastError) {
        this.context.$msgbox({
          type: 'info',
          message: message,
          dangerouslyUseHTMLString: true
        })
      }
    })
    this.context.$http.get(`${this.context.$config.api}/registryservice/plugins/execute/CheckScenarioGroupExistsQuery?scenarioId=${card.id}`,
      {},
      {}
    ).then((response) => {
      if (response.data.exist) {
        let groups = response.data.group_names.join(', ')
        let message = `Сценарий не может быть сохранен с выбранной группой. К группе ${groups} ранее был отнесен сценарий  - ${response.data.scenario_names}`
        this.context.$msgbox({
          type: 'info',
          message: message
        })
      }
    })
  }
}
